exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-dev-tsx": () => import("./../../../src/pages/dev.tsx" /* webpackChunkName: "component---src-pages-dev-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-01-25-teams-are-better-than-individuals-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/2025-01-25-teams-are-better-than-individuals/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-01-25-teams-are-better-than-individuals-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-01-25-xamarin-support-ends-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/2025-01-25-xamarin-support-ends/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-01-25-xamarin-support-ends-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-01-29-blended-team-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/2025-01-29-blended-team/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-01-29-blended-team-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-02-03-monitoring-and-diagnostics-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/2025-02-03-monitoring-and-diagnostics/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-02-03-monitoring-and-diagnostics-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-02-07-apns-server-certificate-update-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/2025-02-07-apns-server-certificate-update/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-02-07-apns-server-certificate-update-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-02-28-app-version-rollouts-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/2025-02-28-app-version-rollouts/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-02-28-app-version-rollouts-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-03-13-mobile-cicd-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/2025-03-13-mobile-cicd/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-2025-03-13-mobile-cicd-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-5-essential-tips-to-get-your-app-published-on-the-app-store-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/5-essential-tips-to-get-your-app-published-on-the-app-store/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-5-essential-tips-to-get-your-app-published-on-the-app-store-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-are-you-over-testing-your-app-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/are-you-over-testing-your-app/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-are-you-over-testing-your-app-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-how-long-does-mobile-take-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/how-long-does-mobile-take/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-how-long-does-mobile-take-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-should-you-localise-your-app-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/should-you-localise-your-app/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-should-you-localise-your-app-index-mdx" */),
  "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-case-studies-2025-01-30-bc-over-testing-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__contentPath}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/case-studies/2025-01-30-BC-over-testing/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-content-path-mdx-frontmatter-slug-tsx-content-file-path-src-content-case-studies-2025-01-30-bc-over-testing-index-mdx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/termsAndConditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

